//Its' Making issue in Web App side

//import Toast from 'react-native-simple-toast';
//import { Alert } from 'react-native';
// import {localize} from '../locales';
// import {ENV_NAME} from 'react-native-dotenv';

//Its' Making issue in Web App side
// import NetInfo from '@react-native-community/netinfo';

// //import { log } from './LogUtils';

// const showAlert = message => {
//   Alert.alert(
//     // localize('app_name'),
//     'BrightSpeed',
//     message,
//     [
//       {
//         // text: localize('btn_ok'),
//         text: 'OK',
//         onPress: () => {
//           log('OK Pressed');
//         },
//       },
//     ],
//     { cancelable: false },
//   );
// };

// const showToast = message => {
//   return Toast.show(message, Toast.LONG);
// };

import { notificationType } from "../../shared/utils/Constants";

const isAppEnv = () => {
  return !!!window.location;
};

const isInternetConnected = async () => {
  // let state = await NetInfo.fetch();
  // return state.isConnected;
  return true;
};
const replacePlaceholders = (str, values) => {
  let modifiedString = str;
  values.forEach((value, index) => {
    const placeholder = new RegExp(`{{.+?}}`);
    modifiedString = modifiedString.replace(placeholder, value);
  });
  return modifiedString;
};
const generateNotificationId = (type, accountId, serviceAddId, ccLastDigits="") => {
 let returnData=0;
  switch(type){
    case "CreditCard":
      returnData = accountId + serviceAddId + ccLastDigits;
    break;
    case "VacationSuspend":
      returnData = accountId + serviceAddId + "VacationSuspend";
    break;
    case "ACPBanner":
      returnData = accountId + serviceAddId + "ACPBanner";
    break;
    case "RoadBore":
      returnData = accountId + serviceAddId + "RoadBore";
    break;
    case "BuryCable":
      returnData = accountId + serviceAddId + "BuryCable";
    break;
    case "suspend":
      returnData = accountId + serviceAddId + "suspend";
    break;
    case "nopaymentmethod":
      returnData = accountId + serviceAddId + "nopaymentmethod";
    break;
    case "Initial-login-CTL-User":
      returnData = accountId + serviceAddId + "Initial-login-CTL-User";
    break;
    case "changePlanPending":
      returnData = accountId + serviceAddId + "changePlanPending";
    break;
    case "changePlanPreInstallation":
      returnData = accountId + serviceAddId + "changePlanPreInstallation";
    break;
    case "User-Suspended-Vacation":
      returnData = accountId + serviceAddId + "User-Suspended-Vacation";
    break;
    case "ReferalBanner":
      returnData = accountId + serviceAddId + "ReferalBanner";
    break;
    case "Initial-login-CTL-Welcome":
      returnData = accountId + serviceAddId + "Initial-login-CTL-Welcome";
    break;
    case "Download-Banner":
      returnData = accountId + serviceAddId + "Download-Banner";
    break;
    case "PaymentArrangements":
      returnData = accountId + serviceAddId + "PaymentArrangements";
    break;
    case "ModemRestarted":
      returnData = accountId + serviceAddId + "ModemRestarted";
    break;
    case "JeopardyBanner":
      returnData = accountId + serviceAddId + "JeopardyBanner";
    break;
    case "AutoPayRemoval":
      returnData = accountId + serviceAddId + "AutoPayRemoval";
    break;
    case "ctlpaypaluser":
      returnData = accountId + serviceAddId + "ctlpaypaluser";
    break;
    case "qbpBanner":
      returnData = accountId + serviceAddId + "qbpBanner";
    break;
    default:
      returnData;
    break;
  }
  return returnData;
};
export {
  isInternetConnected,
  isAppEnv,
  replacePlaceholders,
  generateNotificationId,
};
